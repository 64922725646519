export default [
    {
      title: 'Dashboards',
      icon: 'HomeIcon',
      tagVariant: 'light-warning',
      children: [
        {
          title: 'Dashboards1',
          route: 'dashboardadmin-ecommerce',
        },
        {
          title: 'Dashboards2',
          route: 'admin-dashboard',
        },
      ],
      
    },
    {
      title: 'จัดการคำสั่งซื้อ', 
      icon: 'FileTextIcon',   
      children: [
        {
          title: 'รายการลงออเดอร์(รอพิมพ์)',
          route: 'admin-wait-order-list',
        },
        {
          title: 'รายการลงออเดอร์(พิมพ์แล้ว)',
          route: 'admin-printed-order-list',
        },
        {
          title: 'รายการเปิดบิล',
          route: 'admin-openbill-list',
        },
        {
          title: 'เปิดบิล',
          route: 'admin-openbill',
        },
      ],
    },
    {
      title: 'จัดการคลังสินค้า', 
      icon: 'MessageSquareIcon',   
      children: [
        {
          title: 'รายการคลังสินค้า',
          route: 'admin-warehouse-list',
        },
        // {
        //   title: 'เพิ่มสต๊อกสินค้า',
        //   route: 'admin-warehouse-create',
        // },
      ],
    },
    {
      title: 'จัดการสินค้า', 
      icon: 'CheckSquareIcon',   
      children: [
        {
          title: 'รายการสินค้า',
          route: 'admin-products-list',
        },
        // {
        //   title: 'เพิ่มสินค้า',
        //   route: 'admin-products-create',
        // },
      ],
    },
    {
      title: 'จัดการสมาชิก', 
      icon: 'UserPlusIcon',   
      children: [
        {
          title: 'รายการสมาชิก',
          route: 'admin-members-list',
        },
        // {
        //   title: 'เพิ่มสมาชิก',
        //   route: 'admin-members-create',
        // },
      ],
    },
    {
      title: 'จัดการลูกค้า', 
      icon: 'UserIcon',   
      children: [
        {
          title: 'รายการลูกค้า',
          route: 'admin-customer-list',
        },
        // {
        //   title: 'เพิ่มสมาชิก',
        //   route: 'admin-members-create',
        // },
      ],
    },
  ]
  