export default [
    {
      title: 'Dashboards',
      icon: 'HomeIcon',
  
      tagVariant: 'light-warning',
      route: 'dashboard-ecommerce',
    },
    // {
    //   title: 'เปิดบิล',
    //   route: 'open-bill',
    //   icon: 'ClipboardIcon',   
    // },
    {
      title: 'ลงออเดอร์',
      route: 'confirm-order',
      icon: 'ShoppingCartIcon',   
    },
    {
      title: 'ประวัติคำสั่งซื้อ',
      route: 'history-order',
      icon: 'FileTextIcon',   
    },
    {
      title: 'สินค้าของฉัน',
      route: 'my-products',
      icon: 'BoxIcon',   
    },
    {
      title: 'ลูกค้าที่ต้องโทรหา',
      route: 'my-customer-today',
      icon: 'PhoneIcon',   
    },
    {
      title: 'ออกจากระบบ',
      route: 'shop-logout',
      icon: 'LogOutIcon',   
    },
   
  ]
  